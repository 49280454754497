html, body {
    font-family: "Urbanist", sans-serif;
    font-size: 15px;
    background-color: #fff !important;
    width: 100%;
}
li {
    padding: 10px 0;
    font-weight: 700;
}

.hide {
    display: none;
}

.nav-link{
    text-decoration: none;
    color: #6f6d6d;
}
.nav-tabs .nav-link {
    border-color: #000;
}
.navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 18px !important;
    padding-right: 18px !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #daa520;
    border-color: #fff;
}
.form-control {
    border-radius: 20px !important;
    opacity: 0.8;
}
.grecaptcha-badge {
    display: none !important;
}


.Menu-letras{
    color: #000;
    /*background-color: #cf9f51;*/
    background-color: transparent;
    font-family: "Urbanist", sans-serif;
    position: absolute;
    text-decoration: none;
    font-size: 15px;
    font-weight: 600;
  }
.mision{
    background-color: transparent;
    padding: 20px;
}
.Menu-fondo{
    color:#8c847c;
}
.nav-link:hover{
color: #a87c35;
}
.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link {
    color: #a87c35;
}
.clearfixe{
    height: 80px;
}
.titulos{
    font-size: 40px;
    letter-spacing: 10px;
    font-weight: bold;
    text-transform: uppercase;
    color: goldenrod;
    text-align: center;
    padding-bottom: 50px;
}
.subtitulos{
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
    color: goldenrod;
    text-align: center;
}
body{
    /*background: #cdccc5;*/
    background: #fff;
    /*padding: 90px 20px 0;*/
}
.logo{
    padding: 5px;
}

.nav{
    width: 300px;
}
.seccion{
    color:#FCB207;
}
.nav__link{
    color: #303440;
    display: block;
    padding: 15px 0;
    text-decoration: none;
}

.nav__link--inside{
    border-radius: 6px ;
    padding-left: 20px;
    text-align: left;
}

.nav__link--inside:hover{
    background: #F6F8FA;
}


.arrow .list__arrow{
    transform: rotate(90deg);
}

.list__arrow{
    margin-left: auto;
    transition: transform .3s;
}

.list__show{
    width: 80%;
    margin-left: auto;
    border-left: 2px solid #303440;
    list-style: none;
    transition: height .4s;
    height: 0;
}
.form-control-dark {
    border-color: var(--bs-gray);
  }
  .form-control-dark:focus {
    border-color: #fff;
    box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
  }
  .redondeo{
    width: 250px;
    height: 100px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #5cb85c;
    text-align: center;
  }
  .cajastexto{
    
    height: 65%;
    padding: 5px;
    float: right;
  }
  .direccion{
    color: #704f03;
    font-size: larger;
    font-style: inherit;
  }
  .text-small {
    font-size: 85%;
  }
.tarjeta-mision {
    font-size: 15px;
    text-align: center;
    color: #000000;
}
.card-body:hover {
    transform: scale(1.1);
}
.card-title {
    font-size: 15px !important;
    text-align: center;
    font-weight: 900;
    color: #68554a;
}
.service-icons {
    width: 80px;
    margin-bottom: 20px;
}
.service-icons-detail {
    width: 100%;
    max-width: 50px;
}
.service-title {
    font-size: 10px !important;
    padding-top: 10px;
}
.col-md-custom {
    padding-bottom: 15px;    
}
.footer {
    padding-top: 50px !important;
    padding-bottom: 50px;
    background-color: transparent;
    background-image: url(./img/footer.png);
    background-size: cover;
    /*background-position: bottom;*/
    border: 0 !important;
  }
  .footer-logo {
    height: 100px;
  }
  .footer-icon {
    color: #704f03;
    font-size: 22px;
    width: 22px;
    cursor: pointer;
  }
  .footer-text-big {
    position: relative;
    top: 4px;
    color: #704f03;
    font-size: 22px;
    font-weight: 400;
    cursor: pointer;
  }
  .footer-text {
    color: #704f03;
    font-size: 22px;
    font-weight: 600;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }
  .footer-text:hover {
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  .full-container {
    /*background-color: #fff;*/
    max-width: 100% !important;
  }
  .service-icon {
    width: 100%;
    max-width: 150px;
  }
  .generic-image {
    background-color: #303440;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 300px;
  }
  .section-home {
    padding: 300px 20px;
  }



.mb-2{
    opacity: 1;
}
.valores-title {
    color: goldenrod;
    font-size: 22px;
    text-align: left;
}
.valores{
    font-size: 15px;
    font-weight: 400;
    text-align: left;
}
.carrusel-tamaño{
    width: 75%;
    height: 75%;
}
.val-col-style {
    padding: 50px;
}
.val-row-style {
    padding-top: 30px;
}
.card {
    border: 0 !important;
    background-color: transparent !important;
}
.col-md-custom {
    border: 1px solid #b1b0b0;
}
.client-img {
    padding: 20px;
}
.card-img-top {
    width: 70%;
    text-align: center;
}
.contact-dire {
    font-size: 15px;
    color: #a87c35;
}
.clients-container {
    max-width: 800px;
}



html{
    scroll-behavior: smooth;
}
section[id]{
    scroll-margin-top: 10px;
}
.carrusel-imagen{
    width: 100%;
    border-radius: 30px;
}
.main-c {
    max-width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
}
.section {
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: 1100px;
}
.section-top {
    width: 70%;
    padding-top: 100px;
}

.banner-bg {
    background-image: url(./img/home.jpg);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner-bg-body {
    background-image: url(./img/body.jpg);
    background-position: top;
    background-size: cover;
}
.banner-bg-services {
    background-image: url(./img/services.png);
    background-position: top;
    background-size: cover;
}
.banner-bg-services-b {
    background-image: url(./img/services2.png);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner-bg-contacto {
    background-image: url(./img/contacto.jpg);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner-bg-bis {
    background-image: url(./img/bisness.jpg);
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
}
.banner-container {
    padding: 140px 0 140px 0;
    text-align: center;
    width: 100%;
}
.banner-text-container {
    /*max-width: 1000px;*/
}
.banner-title {
    font-family: "Epilogue", sans-serif;
    font-size: 50px;
    font-weight: 600;
    color: #675549;
    line-height: 60px;
}
.banner-title-page {
    font-family: "Epilogue", sans-serif;
    font-size: 70px;
    font-weight: 700;
    color: #675549;
    line-height: 75px;
}
.banner-subtitle {
    font-family: "Epilogue", sans-serif;
    font-size: 30px;
    font-weight: 700;
    color: #675549;
}
.banner-desc {
    font-size: 18px;
    color: #675549;
    font-weight: 400;
}
.banner-desc-gold {
    color: #BA7C17;
    font-weight: 600;
}
.banner-title-big {
    font-family: "Epilogue", sans-serif;
    font-size: 65px;
    font-weight: 400;
    font-style: italic;
    color: #675549;
    line-height: 70px;
}
.banner-title-jumbo {
    font-family: "Epilogue", sans-serif;
    font-size: 65px;
    font-weight: 400;
    font-style: italic;
    color: #ba7c17;
    line-height: 70px;
}
.banner-title-small {
    font-family: "Epilogue", sans-serif;
    font-size: 20px;
    font-weight: 700;
    color: #675549;
    line-height: 25px;
}
.banner-title-small-new {
    font-family: "Epilogue", sans-serif;
    font-size: 30px;
    font-weight: 500;
    color: #000;
    line-height: 35px;
}
.banner-desc-small {
    font-size: 17px;
    font-weight: 400;
    color: #000;
    line-height: 22px;
}
.section-cls {
    padding: 100px 0 100px 0;
}
.section-color {
    background-color: #f5f3f3;
}

.img-100 {
    width: 100%;
}
.img-80 {
    width: 80%;
}
.img-50 {
    width: 80%;
}
.img-30 {
    width: 30%;
}
.epoligue-font {
    font-family: "Epilogue", sans-serif;
}
.capital-style {
    font-size: 50px;
    font-weight: 900;
}
.capital-brown {
    color: #675549;
}
.capital-gold {
    color: #ba7c17;
}
.img-us {
    width: 90%;
    border-radius: 20px;
}
.row-us {
    padding-bottom: 40px;
}
.solutions-col {
    padding: 0 !important;
    margin: 0 !important;
}
.solutions-img {
    width: 100%;
}
.val-container {
    max-width: 1000px;
}
.val-tabs {
    width: 700px;
}
.service-row-margin {
    margin-bottom: 50px;
}

.navbar-coll {
    background-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
}
.btn-submit {
    background-color: #fff;
    border: 0 !important;
    color: #704f03;
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
}
.btn-submit-brown {
    background-color: #704f03;
    border: 0 !important;
    color: #fff;
    border-radius: 20px;
    padding-left: 25px;
    padding-right: 25px;
}
.nav-back {
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
}

@media (max-width: 460px) {
    .navbar-coll {
        background-color: #fff;
    }
    .section-home {
        padding: 100px 20px;
    }
    .section {
        padding: 30px 20px;
    }
    .col-mobile-hide {
        display: none;
    }
    .banner-title-jumbo {
        padding-top: 20px;
        font-size: 50px;
        line-height: 55px;
    }
    .banner-title-page {
        font-size: 45px;
        line-height: 50px;
    }
    .banner-title-small-new {
        padding-top: 10px;
    }
    .section-top {
        padding-top: 50px;
    }
    .banner-title, .banner-desc {
        text-align: center;
    }
    .titulos {
        font-size: 30px;
    }
    .subtitulos {
        font-size: 25px;
    }
    .solutions-text {
        text-align: center;
    }
    .val-tabs {
        width: auto;
    }
}